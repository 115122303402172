import React, { useContext, useMemo, useRef } from 'react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  DEFAULT_STALE_TIME,
  getErrorStatus,
  getOrgDomain,
  log,
  noop,
  Profile,
  updateIsSeller,
} from 'common';
import { apiClient, appAxiosDeprecated } from './services/httpClients/app';
import { systemClient } from './services/httpClients/systemclient';
import useLocalStorage from './core-utils/commonHooks/useLocalStorage';
import ProfileWrapper from './ProfileWrapper';
import {
  LOGIN_ROUTE,
  MAINTENANCE,
  SYSTEM_LOGIN_ROUTE,
  SYSTEM_ROUTE,
} from './core-utils/routes';
import { getPrevPath } from './services/routes/routeUtils';
import { LDContext, useLDClient } from 'launchdarkly-react-client-sdk';
import { ENVIRONMENT_CONFIG } from '../config/hosts';
import * as Sentry from '@sentry/react';

const LOCAL_STORAGE_KEY: string = 'contextState';
const env = ENVIRONMENT_CONFIG.env.toLowerCase();

export type AuthStateInterface = Partial<Profile> & {
  identityToken?: string;
  isAuthenticated: boolean;
  isSystemAdmin: boolean;
  prevPath?: string;
  reason?: string;
  searchSuffix?: string;
  systemToken?: string;
  token?: string;
};

const initialState: AuthStateInterface = {
  isAuthenticated: false,
  isSystemAdmin: false,
  systemToken: undefined,
  token: undefined,
  identityToken: undefined,
  flowDomain: getOrgDomain(),
  searchSuffix: undefined,
  reason: undefined,
  role: 'anonymous',
  identity: undefined,
};

interface AuthContextType {
  auth: AuthStateInterface;
  setAuth: (state: AuthStateInterface) => void;
  clearAuth: () => void;
  isLoading: boolean;
}

const AuthContext = React.createContext<AuthContextType>({
  auth: initialState,
  setAuth: noop,
  clearAuth: noop,
  isLoading: true,
});

export const LOGGED_OUT_STATE: AuthStateInterface = initialState;

const ERROR_RETRY_WHITELIST = [408, 502, 503, 504];
const FAILURE_COUNT = 2;

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: DEFAULT_STALE_TIME,
      retry: (failureCount, error: unknown) => {
        // retry if the error code is in the whitelist
        const errorCode = getErrorStatus(error);
        return (
          failureCount < FAILURE_COUNT &&
          !!errorCode &&
          ERROR_RETRY_WHITELIST.includes(errorCode)
        );
      },
      refetchOnWindowFocus: false,
      refetchOnMount: true,
    },
  },
});

// need a listener in javascript

const setAxiosHeaders = (newAuth: AuthStateInterface) => {
  log.info('Setting Axios Headers');

  if (newAuth.token) {
    appAxiosDeprecated.defaults.headers.common.Authorization = `Bearer ${newAuth.token}`;
    apiClient.instance.defaults.headers.common.Authorization = `Bearer ${newAuth.token}`;
    if (newAuth.reason) {
      apiClient.instance.defaults.headers.common['cf-reason'] = newAuth.reason;
    } else {
      delete apiClient.instance.defaults.headers.common['cf-reason'];
    }
  }

  if (newAuth.systemToken) {
    systemClient.instance.defaults.headers.common.Authorization = `Bearer ${newAuth.systemToken}`;
  }
};

export const AuthWrapper: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const location = useLocation();
  const navigate = useNavigate();
  const clientErrorInterceptor = useRef<number | null>(null);
  const ldClient = useLDClient();

  const [auth, setAuth, clearAuth] = useLocalStorage<AuthStateInterface>(
    LOCAL_STORAGE_KEY,
    LOGGED_OUT_STATE,
    (newAuth) => {
      setAxiosHeaders(newAuth);
      updateIsSeller(newAuth.flowDomain);

      if (!ENVIRONMENT_CONFIG.ldClientSecret || !ldClient) {
        return;
      }

      const context: LDContext = {
        kind: 'user',
        name: newAuth.user?.username,
        key: newAuth.user?.id || 'guest',
        org: newAuth.flowDomain,
        env,
      };
      // make sure we don't identify before its initialized
      ldClient
        .waitForInitialization(5)
        .then(() => {
          log.debug('setting flag context', context);
          ldClient.identify(context).catch((err) => log.error(err));
        })
        .catch((err) => {
          log.warn('error loading flags for context ', err);
        });
    }
  );

  const tokenUpdate = (event: CustomEvent<{ token: string }>) => {
    setAuth({ ...auth, token: event.detail.token });
  };

  window.addEventListener<any>('token_exchange', tokenUpdate);

  const onApiClientError = (error: unknown) => {
    if (getErrorStatus(error) === 503) {
      if (location.pathname !== MAINTENANCE) {
        // only redirect once as we will be testing the status
        navigate(MAINTENANCE);
      }
    }
    if (getErrorStatus(error) === 401) {
      log.error(error);
      log.error('Logging out');
      Sentry.captureMessage(
        `Logging out due to 401: [${String(error)}])`,
        'error'
      );
      setAuth({
        ...LOGGED_OUT_STATE,
        prevPath: getPrevPath(location.pathname),
      });

      const isLoginPage: boolean = [LOGIN_ROUTE, SYSTEM_LOGIN_ROUTE].includes(
        location.pathname
      );

      const correctLoginPage = location.pathname.startsWith(SYSTEM_ROUTE)
        ? SYSTEM_LOGIN_ROUTE
        : LOGIN_ROUTE;

      if (!isLoginPage) {
        navigate(correctLoginPage);
      }
    }
  };

  if (!clientErrorInterceptor.current) {
    clientErrorInterceptor.current =
      apiClient.instance.interceptors.response.use(
        (response) => response,
        (error) => {
          onApiClientError(error);
          return Promise.reject(error);
        }
      );
  }

  log.info('Rendering Auth');

  const contextValue = useMemo(
    () => ({ auth, setAuth, clearAuth, isLoading: false }),
    [auth, setAuth, clearAuth]
  );

  return (
    <AuthContext.Provider value={contextValue}>
      <QueryClientProvider client={queryClient}>
        <ProfileWrapper>{children}</ProfileWrapper>
      </QueryClientProvider>
    </AuthContext.Provider>
  );
};

export function useAuthState(): AuthContextType {
  return useContext(AuthContext);
}

export function useAuth(): AuthStateInterface {
  return useContext(AuthContext).auth;
}

export const useActiveUser = () => {
  return useAuth().user;
};
