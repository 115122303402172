import { useQueryClient } from '@tanstack/react-query';
import IDField from 'app/src/components/IDField';
import TooltipPill from 'app/src/components/TooltipPill';
import {
  FLAG_TYPES,
  isActionAllowed,
} from 'app/src/core-utils/helperFunctions/userServiceHelper';
import { CUSTOMERS } from 'app/src/core-utils/routes';
import {
  useLinkObjectToExternal,
  useUnlinkExternalObject,
} from 'app/src/services/externalCrm';
import {
  AddressDisplay,
  Button,
  Card,
  CardHeader,
  CardRowValue,
  ConfirmModal,
  Contact,
  formatTimeAgo,
  getErrorMessage,
  getPersonFullName,
  Icon,
  log,
  NavigationIcon,
  PopOutMenu,
  PopOutMenuOption,
  useFlags,
  useToast,
  useTranslation,
} from 'common';
import React, { useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import Page from '../../../components/Page';
import { getCRMExternalSource } from '../../../core-utils/helperFunctions/types';
import { useCustomer } from '../../../services/customer';
import CustomerFormDrawer from './CustomerFormDrawer';
import CustomerInvoiceCard from './Details/CustomerInvoiceCard';
import CustomerProposalCard from './Details/CustomerProposalCard';
import CustomerSubscriptionCard from './Details/CustomerSubscriptionCard';
import { apiClient } from 'app/src/services/httpClients/app';
import LinkCustomerToCrmDrawer from '../LinkEntityToCrmDrawer';
import ExternalEntityLink from 'app/src/components/ExternalEntityLink';

const CustomerPage = () => {
  const { id: customerId } = useParams<{ id: string }>();
  const { invoices, customerCrmLinkUnlink } = useFlags();
  const [customerFormIsOpen, setCustomerFormIsOpen] = useState<boolean>(false);
  const { tk } = useTranslation();
  const canEdit = isActionAllowed(FLAG_TYPES.CUSTOMER, 'update');
  const { data: customer, refetch } = useCustomer(customerId!);
  const [showConfirm, setShowConfirm] = useState(false);
  const [linkToCrmDrawerOpen, setLinkToCrmDrawerOpen] = useState(false);
  const showToast = useToast();
  const queryClient = useQueryClient();
  const nameComponent = (
    <div className="flex h-10 gap-2 items-center">
      <div className="flex flex-col justify-center">
        <Link to={CUSTOMERS}>
          <NavigationIcon.Customers width={32} height={32} />
        </Link>
      </div>
      <div className="flex flex-col justify-center text-lg">
        {customer?.name}
      </div>
      {customer?.partner && (
        <TooltipPill label={tk('Partner')} dataTestId="is-partner" />
      )}
      <span className="text-xs opacity-50 translate-y-[0.45em] font-medium self-center">
        {formatTimeAgo(
          customer?.updatedAt ??
            customer?.billingAddress?.updatedAt ??
            customer?.billingContact?.updatedAt
        )}
      </span>
    </div>
  );

  const { mutate: unlinkCustomerFromCRM } = useUnlinkExternalObject(
    'customer',
    customer?.id!,
    () => {
      setShowConfirm(false);
      showToast.info('Customer unlinked from CRM');
    },
    (err: unknown) => {
      setShowConfirm(false);
      const errorMessage = getErrorMessage(err);
      if (errorMessage) {
        showToast.error(errorMessage);
      } else {
        showToast.error('Failed to unlink customer from CRM');
      }
    },
    queryClient
  );
  const unlinkFromCRM = () => {
    const externalSource = customer && getCRMExternalSource(customer);
    if (externalSource) {
      unlinkCustomerFromCRM({
        sourceId: externalSource.sourceId,
        sourceType: externalSource.sourceType,
      });
    }
  };
  const openEditForm = () => {
    setCustomerFormIsOpen(true);
  };

  const closeCustomerForm = async () => {
    await refetch();
    setCustomerFormIsOpen(false);
  };

  const getContactDisplay = (contact?: Contact) => {
    if (contact) {
      return (
        <div>
          <div title={getPersonFullName(contact)}>
            {getPersonFullName(contact)}
          </div>
          <div className="text-xs" title={contact.email}>
            {contact.email}
          </div>
        </div>
      );
    }
    return '-';
  };

  const hasExternalSource = customer && getCRMExternalSource(customer);

  const actionComponent = (
    <div className="flex h-10 gap-2">
      {canEdit && customerCrmLinkUnlink && (
        <PopOutMenu>
          {hasExternalSource ? (
            <PopOutMenuOption
              dataTestId="customer-unlink-from-crm-button"
              icon={Icon.Unlink}
              onClick={() => setShowConfirm(true)}
              title={tk('Unlink from CRM')}
            />
          ) : (
            <PopOutMenuOption
              dataTestId="customer-link-to-crm-button"
              icon={Icon.Link}
              onClick={() => setLinkToCrmDrawerOpen(true)}
              title={tk('Link to CRM')}
            />
          )}
        </PopOutMenu>
      )}
      <Button
        onClick={openEditForm}
        label="Edit"
        dataTestId="edit-customer-button"
        className={canEdit ? undefined : 'invisible'}
      />
    </div>
  );

  const { mutate: linkCustomerToCrm, isPending } = useLinkObjectToExternal(
    'customer',
    customer?.id!,
    () => {
      showToast.info('Customer linked to CRM');
    },
    (err: unknown) => {
      const errorMessage = getErrorMessage(err);
      if (errorMessage) {
        showToast.error(errorMessage);
      } else {
        showToast.error('Failed to link customer from CRM');
      }
    },
    queryClient
  );

  return (
    <Page leftWidget={nameComponent} rightWidget={actionComponent}>
      <CustomerFormDrawer
        isOpen={customerFormIsOpen}
        customerBeingEdited={customer}
        onCreateCustomer={(c) => log.debug(c)}
        onCloseForm={closeCustomerForm}
      />

      <Card className="mb-2" throughline>
        <div className="flex justify-between">
          <CardHeader name="Customer details" throughline />
          {customer && (
            <ExternalEntityLink
              entity={customer}
              sources={customer.sources ?? []}
            />
          )}
        </div>
        <CardRowValue label="Id">
          <IDField documentID={customer?.id}>
            <div className="tabular-nums">{customer?.id.substring(0, 8)}</div>
          </IDField>
        </CardRowValue>
        <CardRowValue label="Legal name">
          <div className="font-bold">{customer?.name}</div>
        </CardRowValue>
        <CardRowValue label="Default currency">
          <div>{customer?.defaultCurrency}</div>
        </CardRowValue>

        <CardRowValue label="Billing contact">
          {getContactDisplay(customer?.billingContact)}
        </CardRowValue>

        <CardRowValue label="Shipping address">
          <div className="pt-2">
            {customer?.shippingAddress && (
              <AddressDisplay address={customer.shippingAddress} />
            )}
          </div>
        </CardRowValue>

        <CardRowValue label="Billing address">
          <div className="pt-2">
            {customer?.billingAddress && (
              <AddressDisplay address={customer.billingAddress} />
            )}
          </div>
        </CardRowValue>
        {customer?.tin && (
          <CardRowValue label="Tax ID">
            <div className="">{customer.tin}</div>
          </CardRowValue>
        )}
      </Card>
      {customer && <CustomerProposalCard customer={customer} />}
      {customer && <CustomerSubscriptionCard customer={customer} />}
      {customer && invoices && <CustomerInvoiceCard customer={customer} />}
      {customer && (
        <LinkCustomerToCrmDrawer
          headerLabel="Link customer to CRM"
          isOpen={linkToCrmDrawerOpen}
          onClose={() => setLinkToCrmDrawerOpen(false)}
          linkEntityToCRM={linkCustomerToCrm}
          fetchOptions={apiClient.getExternalCustomers}
          isUpdating={isPending}
          entityLabel="Customer"
        />
      )}
      <ConfirmModal
        isOpen={showConfirm}
        onClose={() => setShowConfirm(false)}
        onConfirm={() => unlinkFromCRM()}
      >
        <div>Are you sure you want to unlink this customer from CRM?</div>
      </ConfirmModal>
    </Page>
  );
};

export default CustomerPage;
