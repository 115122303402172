import React from 'react';
import { SyncEvent, ExternalSource } from 'common';
import ExternalLink from './Sync/ExternalLink';
import {
  ConnectableEntity,
  getCRMExternalSource,
} from '../core-utils/helperFunctions/types';

interface Props {
  entity: ConnectableEntity;
  showId?: boolean;
  lastSyncEvent?: SyncEvent;
  customDisplayText?: string;
  logoSize?: number;
  sources?: ExternalSource[];
}

const ExternalEntityLink: React.FC<Props> = ({
  entity,
  showId = false,
  lastSyncEvent,
  customDisplayText,
  logoSize = 24,
  sources,
}) => {
  const externalSource = getCRMExternalSource(entity);
  return (
    <div className="flex align-items-center justify-content-between">
      {sources && sources.length > 0 ? (
        sources.map((source) => (
          <ExternalLink
            key={source.sourceId}
            externalSource={source}
            lastSyncEvent={lastSyncEvent}
            customDisplayText={customDisplayText}
            logoSize={logoSize}
          />
        ))
      ) : (
        <ExternalLink
          externalSource={externalSource}
          showId={showId}
          lastSyncEvent={lastSyncEvent}
          customDisplayText={customDisplayText}
          logoSize={logoSize}
        />
      )}
    </div>
  );
};

export default ExternalEntityLink;
