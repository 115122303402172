import React, { useState } from 'react';

import {
  BillingMethodRequest,
  Button,
  ButtonBar,
  Contract,
  CurrencySearchSelect,
  Drawer,
  FormField,
  getErrorData,
  Input,
  Loading,
  PaymentMethod,
  Select,
  useToast,
  useTranslation,
} from 'common';
import { useQueryClient } from '@tanstack/react-query';
import { useUpdateBillingMethod } from '../../../../services/contract';
import { usePaymentMethods } from '../../../../services/paymentMethods';
import {
  useAllCurrencies,
  useOrgCurrencies,
} from '../../../../services/api/currency/currency';

interface Props {
  contract: Contract;
  isOpen: boolean;
  onCloseForm: () => void;
}

const PaymentForm: React.FC<Props> = ({ contract, isOpen, onCloseForm }) => {
  const { tk } = useTranslation();
  const queryClient = useQueryClient();
  const showToast = useToast();
  const { data: currencies } = useAllCurrencies();
  const { data: orgCurrencies } = useOrgCurrencies();

  const [draft, setDraft] = useState<BillingMethodRequest | undefined>({
    paymentMethodId: contract.billingMethod?.paymentMethod.id,
  });
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState<
    PaymentMethod | undefined
  >(undefined);

  const { data: paymentMethods, isLoading } = usePaymentMethods();

  const handleSuccess = () => {
    showToast.success('Success');
    closeForm();
  };

  const handleError = (error: unknown) => {
    const msg = JSON.stringify(getErrorData(error));
    showToast.error(`Billing method update failed: ${msg}`);
  };

  const { mutate: updateBillingMethod } = useUpdateBillingMethod(
    contract.id,
    handleSuccess,
    handleError,
    queryClient
  );

  const isFormValid = () => {
    if (!draft || !selectedPaymentMethod) return false;

    if (selectedPaymentMethod.paymentType === 'cc')
      return (
        draft.stripeAccounts?.paymentMethodId !== undefined &&
        draft.stripeAccounts.customerId !== undefined
      );

    if (selectedPaymentMethod.paymentType === 'manual')
      return draft.virtualAccount?.name !== undefined;

    return false;
  };

  const submitIfValid = () => {
    if (isFormValid() && draft) {
      updateBillingMethod(draft);
    }
  };

  const closeForm = () => {
    setDraft(undefined);
    onCloseForm();
  };

  const footer = (
    <ButtonBar>
      <Button block label="Cancel" onClick={closeForm} type="secondary" />
      <Button
        block
        isDisabled={!isFormValid()}
        label="Update"
        onClick={submitIfValid}
      />
    </ButtonBar>
  );

  if (isLoading || !paymentMethods) return <Loading />;

  return (
    <Drawer
      footer={footer}
      header="Update payment method"
      isOpen={isOpen}
      onClose={closeForm}
    >
      <FormField label={tk('Payment method')}>
        <Select
          onChange={(value) => {
            // clears out the draft on change
            setDraft({ paymentMethodId: value });
            setSelectedPaymentMethod(
              paymentMethods.find((pm) => pm.id === value)
            );
          }}
          options={paymentMethods
            .filter((pm) => pm.enabled && pm.configured)
            .map((pm) => ({
              value: pm.id,
              name: `${pm.name} - ${pm.description}`,
            }))}
          value={draft?.paymentMethodId}
        />
      </FormField>

      {selectedPaymentMethod?.paymentType === 'cc' && (
        <>
          <FormField label="Stripe customer id">
            <Input
              onChange={(val) => {
                setDraft((prev) => ({
                  ...prev,
                  stripeAccounts: {
                    customerId: val,
                    ...prev?.stripeAccounts,
                  },
                }));
              }}
            />
          </FormField>

          <FormField label="Stripe payment method id">
            <Input
              onChange={(val) => {
                setDraft((prev) => ({
                  ...prev,
                  stripeAccounts: {
                    paymentMethodId: val,
                    ...prev?.stripeAccounts,
                  },
                }));
              }}
            />
          </FormField>
        </>
      )}
      {selectedPaymentMethod?.paymentType === 'manual' && (
        <FormField label="Virtual account name">
          <Input
            onChange={(val) => {
              setDraft((prev) => ({
                ...prev,
                virtualAccount: {
                  name: val,
                },
              }));
            }}
          />
        </FormField>
      )}

      {selectedPaymentMethod?.paymentType === 'direct_debit' && (
        <>
          <FormField label="Legal name">
            <Input
              onChange={(val) => {
                setDraft((prev) => ({
                  ...prev,
                  manualRequest: { ...prev?.manualRequest, legalName: val },
                }));
              }}
            />
          </FormField>
          <FormField label="Name">
            <Input
              onChange={(val) => {
                setDraft((prev) => ({
                  ...prev,
                  manualRequest: { ...prev?.manualRequest, name: val },
                }));
              }}
            />
          </FormField>
          <FormField label="Institution name">
            <Input
              onChange={(val) => {
                setDraft((prev) => ({
                  ...prev,
                  manualRequest: {
                    ...prev?.manualRequest,
                    institutionName: val,
                  },
                }));
              }}
            />
          </FormField>
          <FormField label="Account number">
            <Input
              onChange={(val) => {
                setDraft((prev) => ({
                  ...prev,
                  manualRequest: { ...prev?.manualRequest, accountNumber: val },
                }));
              }}
            />
          </FormField>
          <FormField label="Routing number">
            <Input
              onChange={(val) => {
                setDraft((prev) => ({
                  ...prev,
                  manualRequest: { ...prev?.manualRequest, routingNumber: val },
                }));
              }}
            />
          </FormField>
          <FormField label="Owner type">
            <Input
              onChange={(val) => {
                setDraft((prev) => ({
                  ...prev,
                  manualRequest: { ...prev?.manualRequest, ownerType: val },
                }));
              }}
              value="business"
            />
          </FormField>
          <FormField label="Currency">
            <CurrencySearchSelect
              currencies={currencies}
              dataTestId="account-currency-select"
              defaultSelectedCurrency="USD"
              onSelectCurrency={(val) => {
                setDraft((prev) => ({
                  ...prev,
                  manualRequest: {
                    ...prev?.manualRequest,
                    currency: val.toLowerCase(),
                  },
                }));
              }}
              orgCurrencies={orgCurrencies}
            />
          </FormField>
          <FormField label="Account type">
            <Input
              onChange={(val) => {
                setDraft((prev) => ({
                  ...prev,
                  manualRequest: { ...prev?.manualRequest, accountType: val },
                }));
              }}
              value="depository"
            />
          </FormField>
        </>
      )}
    </Drawer>
  );
};

export default PaymentForm;
